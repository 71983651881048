import styled from "styled-components"

export const TagsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -1em;

  font-family: var(--sans-serif);
  font-weight: bold;
  font-size: var(--fs-label);
  text-transform: uppercase;

  @media (max-width: 800px) {
  }
`
export const Tag = styled.div`
  margin-bottom: 1em;
  margin-right: 1em;
  padding: 0.5em 1em;

  color: inherit;
  border: 2px solid;
  border-radius: calc(var(--default-radius) / 2);
`
