import React from "react"
import styled, { css } from "styled-components"
import { Logo } from "./Icons"
import { Constraint, Section } from "./Layout"
import { AppLogo } from "./Navbar"
import { TextLink } from "./Typography"

const FooterWrapper = styled.footer`
  color: var(--white);
  background: var(--default-gradient);

  ${Logo} {
  }
`

const FooterConstraint = styled(Constraint)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`

const ScrollTop = styled.a`
  --size: 70px;

  position: fixed;
  z-index: 10;
  bottom: 6vmin;
  right: 6vmin;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white);

  font-size: var(--fs-h4);
  text-align: center;

  width: var(--size);
  height: var(--size);

  border-radius: 50%;
  background: var(--default-gradient);
  box-shadow: var(--shadow-lg);

  text-decoration: none;

  :visited {
    color: var(--white);
  }

  @media (max-width: 800px) {
    --size: 60px;
  }
`

const Footer = () => {
  return (
    // <FooterWrapper>
    //   <Section>
    //     <FooterConstraint>
    //       <AppLogo>
    //         <Logo />
    //       </AppLogo>

    //       <TextLink href="#">scroll to top</TextLink>
    //     </FooterConstraint>
    //   </Section>
    // </FooterWrapper>

    <ScrollTop href="#">top</ScrollTop>
  )
}

export default Footer
