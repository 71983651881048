import React from "react"
import { Constraint, Section, TextConstraint } from "../components/Layout"
import {
  ProjectTitle,
  ProjectSubtitle,
  ProjectThumbnail,
  ProjectThumbnailWrapper,
  ProjectImgGroup,
  ProjectPageWrapper,
  ProjectHeroSection,
  ProjectHeroText,
  NextProject
} from "../components/ProjectElements"
import {
  TextLink,
  CopySubtitle,
  CopyTitle,
  CopyLabel,
  CopyLabelText
} from "../components/Typography"
import { TagsWrapper, Tag } from "../components/Tags"
import Footer from "../components/Footer"
import ScrollToTop from "../helpers/ScrollToTop"

const projectName = "Pefin"

const Pefin = () => {
  return (
    <>
      <ScrollToTop />

      <ProjectPageWrapper>
        <Section>
          <Constraint>
            <ProjectHeroSection>
              <ProjectThumbnailWrapper projectPage>
                <ProjectThumbnail
                  src={require("../images/pefin/pefin-homepage.png")}
                  alt="Pefin Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/pefin/pefin-financial-plan-01.png")}
                  alt="Pefin Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/pefin/pefin-assistant-02.png")}
                  alt="Pefin Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/pefin/pefin-onboarding-02.png")}
                  alt="Pefin Project Thumbnail"
                />
              </ProjectThumbnailWrapper>
            </ProjectHeroSection>

            <TextConstraint>
              <ProjectHeroText>
                <ProjectTitle>{projectName}</ProjectTitle>
                <ProjectSubtitle>AI Financial Advisor</ProjectSubtitle>
                <TagsWrapper>
                  <Tag>Front End</Tag>
                  <Tag>UX Design</Tag>
                  <Tag>UX Engineering</Tag>
                </TagsWrapper>
              </ProjectHeroText>

              <CopyLabel>Timeline</CopyLabel>
              <CopyLabelText>July 2016 &ndash; Present</CopyLabelText>

              <CopyLabel>Visit the Site</CopyLabel>
              <CopyLabelText>
                <TextLink href="https://www.pefin.com/">
                  https://www.pefin.com/
                </TextLink>
              </CopyLabelText>

              <p>
                Pefin is an AI financial advisor that helps ordinary people plan
                their lives without breaking the bank. Users can add their
                financial information and life plans and Pefin gives advice on
                how to best achieve those goals.
              </p>

              <p>
                It’s currently free to{" "}
                <TextLink href="http://pefin.com/signup">
                  make an account
                </TextLink>{" "}
                if you want to try it out for yourself.
              </p>

              <p>
                Since I started working at Pefin I’ve gotten the unique
                opportunity to work in various teams between design and front
                end development.
              </p>

              <CopyTitle>Pefin Web App</CopyTitle>

              <CopyLabel>Tools</CopyLabel>
              <CopyLabelText>HTML, LESS, JavaScript, React</CopyLabelText>

              <p>
                As a Front End Developer, I implemented designs and prototyped
                interactions/animations for the Pefin Web App&mdash;including
                the new version of onboarding, the Pefin Assistant, and a
                responsive design overhaul of the entire web app.
              </p>

              <p>
                As a UX Designer, I created and prototyped responsive designs
                for the Pefin Web App.
              </p>
            </TextConstraint>

            <ProjectImgGroup>
              <figure>
                <img
                  src={require("../images/pefin/pefin-homepage.png")}
                  alt="Pefin Web App: Homepage"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-financial-plan-01.png")}
                  alt="Financial Plan Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-onboarding-01.png")}
                  alt="Onboarding: Splash screen"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-onboarding-02.png")}
                  alt="Onboarding: Name and birthdate inputs"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-onboarding-03.png")}
                  alt="Onboarding: Choosing how to connect accounts"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-onboarding-04.png")}
                  alt="Onboarding: User Financial Picture infographic"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-onboarding-05.png")}
                  alt="Onboarding: Add an IRA account"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-assistant-01.png")}
                  alt="Pefin Assistant: Investing questions"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-assistant-02.png")}
                  alt="Pefin Assistant: Adding plans"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/pefin/pefin-assistant-03.png")}
                  alt="Pefin Assistant: Infographic about the user’s savings pllan"
                />
              </figure>
            </ProjectImgGroup>

            <TextConstraint>
              <CopyTitle>pefin.com</CopyTitle>

              <CopyLabel>Tools</CopyLabel>
              <CopyLabelText>HTML, LESS, JavaScript, React</CopyLabelText>

              <p>
                As a Front End Developer, I implemented designs and prototyped
                interactions/animations for the Pefin Web App. I also designed
                and prototyped (in code)responsive designs from existing desktop
                designs. I then refactored the front end of{" "}
                <TextLink href="http://pefin.com">pefin.com</TextLink> to
                implement these designs.
              </p>

              <CopyTitle>Pefin Mobile App (Unreleased)</CopyTitle>

              <CopyLabel>Tools</CopyLabel>
              <CopyLabelText>Sketch, InVision, Principle, Framer</CopyLabelText>

              <p>
                As a UX Designer, I collaborated on various product
                specifications with product owners and used them to create user
                tested designs and prototypes of the Pefin Mobile App and the
                Pefin Dashboard.
              </p>

              <p>
                Unfortunately, the product hasn’t been released so I’m not at
                liberty to show them here.
              </p>

              <CopyTitle>Pefin Web App v2 (Unreleased)</CopyTitle>

              <p>
                As a UX Engineer, I worked with the front end team to develop
                the new version of the Pefin Web App using React, Storybook, and
                Styled Components. During this time I learned about, advocated,
                and implemented accessibility into the workflow of the design
                and front end teams based on WCAG standards.
              </p>

              <p>This project is also unreleased, so I can’t show it here.</p>
            </TextConstraint>
          </Constraint>
        </Section>

        {/* <Section>
          <Constraint>
            <NextProject to="/quiikmeet">QuiikMeet</NextProject>
          </Constraint>
        </Section> */}
      </ProjectPageWrapper>

      <Footer />
    </>
  )
}

export default Pefin
