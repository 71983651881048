import styled from "styled-components"

export const TextLink = styled.a`
  color: inherit;

  :visited {
    color: inherit;
  }
`

export const CopyTitle = styled.h2`
  font-size: var(--fs-h3);
  margin: 2em 0 0.5em 0;
`

export const CopySubtitle = styled.h3`
  font-size: var(--fs-h4);
  margin: 1.5em 0 0.5em 0;
`

export const CopyLabel = styled.h4`
  font-family: var(--sans-serif);
  font-size: var(--fs-label);
  text-transform: uppercase;
  margin: 1em 0 0em 0;
`

export const CopyLabelText = styled.p`
  margin: 0;
  margin-bottom: 1em;
`
