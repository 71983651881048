import React from "react"

export const Logo = props => {
  return (
    <svg viewBox="0 0 534 346">
      <path
        d="M409.174 0.20166V17.341H432.192C440.893 17.341 447.63 19.7293 452.542 24.3653C457.455 29.1419 459.981 35.7448 459.981 44.174C459.981 50.2149 459.279 55.4129 457.735 59.6275L379.139 275.555L313.175 107.533C312.473 105.286 311.772 103.038 311.07 100.79C310.929 100.228 310.649 99.8066 310.508 99.2447C309.245 95.873 307.982 92.5013 306.579 89.2701C306.017 87.8653 305.315 86.3199 304.614 84.9151C303.912 83.5102 303.351 81.9648 302.649 80.56C301.105 77.4693 299.561 74.5191 297.877 71.5688C282.158 44.5954 263.07 25.3488 240.474 13.8289C226.86 6.94502 211.141 2.73042 193.316 1.04458C190.79 0.763606 188.264 0.62312 185.597 0.482633C185.176 0.482633 184.615 0.342147 184.194 0.342147C180.966 0.342147 177.738 0.20166 174.51 0.20166H0.335693V17.341H34.7214C40.616 17.341 45.2476 18.7459 48.616 21.5556C51.9844 24.3653 53.6686 28.1585 53.6686 32.7945V304.636C53.6686 310.115 52.1247 314.189 49.037 316.718C45.9493 319.247 40.3353 320.652 32.3354 320.652H0.335693V337.791H156.264C182.931 337.791 204.123 335.262 220.123 330.064C241.456 323.04 259.421 312.503 274.298 298.595C289.175 284.546 300.123 268.25 307.28 249.565C308.544 246.334 309.666 243.103 310.789 239.731C310.789 239.591 310.789 239.591 310.789 239.45C311.912 236.219 312.754 232.847 313.736 229.476C313.736 229.476 313.736 229.476 313.736 229.335C313.736 229.335 313.736 229.335 313.736 229.476L359.631 345.799H376.192L480.472 63.4206C486.507 46.9837 493.384 35.1828 500.823 28.018C508.401 20.8532 515.98 17.341 523.559 17.341H533.664V0.20166H409.174ZM252.544 263.614C245.948 279.629 236.404 291.992 223.772 300.843C204.404 314.049 181.948 320.652 156.264 320.652H127.633C118.51 320.652 112.054 319.247 108.265 316.437C104.475 313.627 102.51 309.413 102.51 303.934V34.6209C102.51 28.8609 104.194 24.5058 107.422 21.5556C110.651 18.6054 115.703 17.2005 122.58 17.2005H160.896C194.58 17.2005 219.843 28.299 236.544 50.3554C256.193 76.4859 266.018 117.789 266.018 174.124C266.018 212.196 261.526 241.979 252.544 263.614Z"

        // fill="url(#paint0_linear)"
      />
      {/* <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.335693"
          y1="0.20166"
          x2="217.528"
          y2="486.882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A69" />
          <stop offset="1" stopColor="#CA4048" />
        </linearGradient>
      </defs> */}
    </svg>
  )
}

export const CodepenIcon = props => {
  return (
    <svg viewBox="0 0 469.09 469.09">
      <path
        d="M249.55,15C120,15,15,120,15,249.55S120,484.09,249.55,484.09s234.54-105,234.54-234.54S379.09,15,249.55,15Zm0,430.58c-108.09,0-196-87.94-196-196s87.94-196,196-196,196,87.94,196,196S357.64,445.58,249.55,445.58Z"
        transform="translate(-15 -15)"
      />
      <path
        d="M394.46,203.74c0-.19-.07-.36-.1-.54-.06-.36-.13-.71-.22-1s-.13-.4-.19-.6-.2-.61-.32-.91-.19-.41-.28-.61a8,8,0,0,0-.43-.82c-.11-.2-.23-.39-.35-.58s-.34-.52-.53-.76-.28-.37-.42-.54-.41-.46-.63-.67-.32-.34-.49-.49-.47-.41-.71-.6-.37-.29-.56-.42L389,195,256.46,106.62a12.47,12.47,0,0,0-13.83,0L110.07,195l-.2.16c-.2.13-.38.28-.56.42a8.32,8.32,0,0,0-.71.6,5.78,5.78,0,0,0-.5.49c-.22.21-.42.44-.62.67s-.29.36-.43.54-.36.5-.52.76-.25.38-.36.58-.29.54-.42.82-.2.4-.28.61-.22.6-.32.91-.14.39-.2.6-.15.69-.21,1c0,.18-.08.35-.1.54a10.84,10.84,0,0,0-.12,1.62v88.38a11.07,11.07,0,0,0,.12,1.63c0,.17.07.36.1.53.06.35.12.7.21,1.05s.13.4.2.6.2.61.32.92.18.4.28.6.27.56.42.83.23.38.36.57.34.51.52.76.28.36.43.54.4.46.62.67a5.78,5.78,0,0,0,.5.49,8.23,8.23,0,0,0,.71.59c.18.15.36.29.56.43s.13.11.2.15l132.56,88.38a12.44,12.44,0,0,0,13.83,0L389,304.1l.21-.15c.19-.14.38-.28.56-.43s.48-.38.71-.59.33-.32.49-.49.43-.44.63-.67.29-.35.42-.54.36-.5.53-.76.24-.38.35-.57a9.33,9.33,0,0,0,.43-.83l.28-.6c.12-.31.22-.61.32-.92s.14-.4.19-.6.16-.7.22-1.05c0-.17.08-.36.1-.53a12.63,12.63,0,0,0,.11-1.63V205.36A12.36,12.36,0,0,0,394.46,203.74ZM262,140.28l97.66,65.1-43.62,29.18-54-36.15Zm-24.93,0v58.13l-54,36.15-43.62-29.18ZM129.45,228.69l31.18,20.86-31.18,20.86ZM237.08,358.82l-97.65-65.1,43.62-29.17,54,36.14ZM249.55,279l-44.08-29.49,44.08-29.49,44.08,29.49ZM262,358.82V300.69l54-36.14,43.62,29.17Zm107.63-88.41-31.18-20.86,31.18-20.86Z"
        transform="translate(-15 -15)"
      />
    </svg>
  )
}

export const LinkedInIcon = props => {
  return (
    <svg viewBox="0 0 510 510">
      <path
        d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
			 M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
			S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
			V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"
      />
    </svg>
  )
}

export const EmailIcon = props => {
  return (
    <svg viewBox="0 0 490.2 490.2">
      <path
        d="M420.95,61.8C376.25,20.6,320.65,0,254.25,0c-69.8,0-129.3,23.4-178.4,70.3s-73.7,105.2-73.7,175
		c0,66.9,23.4,124.4,70.1,172.6c46.9,48.2,109.9,72.3,189.2,72.3c47.8,0,94.7-9.8,140.7-29.5c15-6.4,22.3-23.6,16.2-38.7l0,0
		c-6.3-15.6-24.1-22.8-39.6-16.2c-40,17.2-79.2,25.8-117.4,25.8c-60.8,0-107.9-18.5-141.3-55.6c-33.3-37-50-80.5-50-130.4
		c0-54.2,17.9-99.4,53.6-135.7c35.6-36.2,79.5-54.4,131.5-54.4c47.9,0,88.4,14.9,121.4,44.7s49.5,67.3,49.5,112.5
		c0,30.9-7.6,56.7-22.7,77.2c-15.1,20.6-30.8,30.8-47.1,30.8c-8.8,0-13.2-4.7-13.2-14.2c0-7.7,0.6-16.7,1.7-27.1l18.6-152.1h-64
		l-4.1,14.9c-16.3-13.3-34.2-20-53.6-20c-30.8,0-57.2,12.3-79.1,36.8c-22,24.5-32.9,56.1-32.9,94.7c0,37.7,9.7,68.2,29.2,91.3
		c19.5,23.2,42.9,34.7,70.3,34.7c24.5,0,45.4-10.3,62.8-30.8c13.1,19.7,32.4,29.5,57.9,29.5c37.5,0,69.9-16.3,97.2-49
		c27.3-32.6,41-72,41-118.1C488.05,152.9,465.75,103,420.95,61.8z M273.55,291.9c-11.3,15.2-24.8,22.9-40.5,22.9
		c-10.7,0-19.3-5.6-25.8-16.8c-6.6-11.2-9.9-25.1-9.9-41.8c0-20.6,4.6-37.2,13.8-49.8s20.6-19,34.2-19c11.8,0,22.3,4.7,31.5,14.2
		s13.8,22.1,13.8,37.9C290.55,259.2,284.85,276.6,273.55,291.9z"
      />
    </svg>
  )
}

export const ResumeIcon = props => {
  return (
    <svg viewBox="0 0 77.86 90">
      <path
        d="M18.57,5a7.54,7.54,0,0,0-7.5,7.5v75a7.54,7.54,0,0,0,7.5,7.5H81.43a7.54,7.54,0,0,0,7.5-7.5v-75A7.54,7.54,0,0,0,81.43,5Zm0,5H81.43a2.44,2.44,0,0,1,2.5,2.5v75a2.44,2.44,0,0,1-2.5,2.5H18.57a2.44,2.44,0,0,1-2.5-2.5v-75A2.44,2.44,0,0,1,18.57,10ZM50,18.12a6.93,6.93,0,1,0,6.79,6.93A6.85,6.85,0,0,0,50,18.12ZM42.8,33.89c-4.77,0-9,8-9,12.94H66.16c0-4.91-4.19-12.92-9-12.94ZM21.94,56.65v5H77.57v-5Zm0,10.2v4.94H77.57V66.85Zm0,10.19V82H77.57V77Z"
        transform="translate(-11.07 -5)"
      />
    </svg>
  )
}
