import React from "react"
import styled, { css } from "styled-components"
import { CodepenIcon, LinkedInIcon, ResumeIcon, EmailIcon } from "./Icons"
import { TextLink } from "./Typography"
import { Constraint, Section } from "./Layout"

const mobileBP = "480px"

const HeroSectionWrapper = styled(Section)`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--default-gradient);

  min-height: 100vh;
  width: 100%;

  ${"" /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 40vw)); */}

  font-family: var(--serif);
  color: var(--white);

  /*
  ::after {
    content: "";
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;

    height: 70vh;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      var(--background-color) 100%
    );
  }
  */
`

const HeroSectionInner = styled.div`
  position: relative;
  max-width: 800px;
  ${"" /* margin: 0 auto; */}
`

const HeroTitle = styled.h1`
  position: relative;
  z-index: 1;

  font-size: var(--fs-large);
  font-weight: ${p => (p.secondary ? "normal" : null)};
  line-height: 1.15;

  margin: 0;
  margin-bottom: 0.2em;

  :last-of-type {
  }

  code {
    margin-left: -0.2em;
  }

  @media (max-width: 590px) {
    font-size: var(--fs-h1);
  }
  @media (max-width: 375px) {
    font-size: var(--fs-h2);
  }
`

const HeroSubtitle = styled.h2`
  position: relative;
  z-index: 1;

  font-size: var(--fs-h2);
  font-weight: normal;

  margin: 0;
  margin-bottom: 0.125em;

  @media (max-width: ${mobileBP}) {
    font-size: var(--fs-h3);
  }
`

const HeroBlurb = styled.p`
  position: relative;
  z-index: 1;
  font-size: var(--fs-h4);
`

const SocialLinksWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;

  @media (max-width: ${mobileBP}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  margin-right: 1em;
  font-size: var(--fs-h4);

  ${"" /* text-decoration: none; */}

  :visited {
    color: inherit;
  }

  :last-of-type {
    margin-right: 0;
  }

  span {
    display: none;
  }

  @media (max-width: ${mobileBP}) {
    margin-bottom: 1em;
  }
`

const LinkIcon = styled.div`
  width: var(--fs-h4);
  height: var(--fs-h4);
  margin-right: 0.25em;

  display: none;

  svg {
    fill: var(--white);
  }
`

const Portrait = styled.img`
  z-index: 0;
  position: absolute;
  width: 30vmin;
  height: 30vmin;
  right: 0;
  top: 0;
  border-radius: 50%;

  transform: translate(20%, -20%);
`

const HeroSection = () => {
  return (
    <HeroSectionWrapper>
      <Constraint>
        <HeroSectionInner>
          {/* <Portrait
            src={require("../images/portrait.png")}
            alt="Picture of me"
          /> */}

          <HeroSubtitle>Hey, I’m Dang-Nam.</HeroSubtitle>

          <HeroTitle>
            I <em>design</em> and <br />
            <code>{"{code}"}</code> things.
          </HeroTitle>

          <HeroBlurb>
            Currently, I’m a UX Engineer at{" "}
            <TextLink href="http://pefin.com">Pefin</TextLink> but I also have
            experience in UX Design and Front End Development. On my free time I
            like to cook, play League of Legends, and do karaoke with my
            friends.
          </HeroBlurb>

          <SocialLinksWrapper>
            <SocialLink href="https://codepen.io/dangnam/">
              <LinkIcon>
                <CodepenIcon />
              </LinkIcon>
              Codepen
            </SocialLink>

            <SocialLink href="https://www.linkedin.com/in/dangnamvu/">
              <LinkIcon>
                <LinkedInIcon />
              </LinkIcon>
              LinkedIn
            </SocialLink>

            <SocialLink href={require("../docs/resume.pdf")} target="_blank">
              <LinkIcon>
                <ResumeIcon />
              </LinkIcon>
              Résumé
            </SocialLink>

            <SocialLink href="mailto:dangnamvu@outlook.com">
              <LinkIcon>
                <EmailIcon />
              </LinkIcon>
              Email
            </SocialLink>
          </SocialLinksWrapper>
        </HeroSectionInner>
      </Constraint>
    </HeroSectionWrapper>
  )
}

export default HeroSection
