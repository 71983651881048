import styled, { css } from "styled-components"
import { Link } from "react-router-dom"

export const Section = styled.section`
  padding: ${p => (p.noVert ? "0 10vmin " : "12.5vmin 10vmin")};

  @media (max-width: 480px) {
    padding: ${p => (p.noVert ? "0 6vmin" : "9vmin 6vmin")};
  }
`

export const Constraint = styled.div`
  width: 100%;
  height: 100%;
  max-width: var(--container-mw);
  margin: 0 auto;
`

export const TextConstraint = styled.div`
  position: relative;
  z-index: 1;

  max-width: var(--text-mw);
  margin: 0 auto;
`
