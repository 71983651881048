import { createGlobalStyle, css } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    /* Colors */
    --white: hsl(0, 100%, 100%);
    
    --red: hsl(0, 49%, 50%);
    --pink: hsl(346, 100%, 61%);

    --dark-red: hsl(0, 56%, 8%);
    --dark-blue: hsl(220, 29%, 12%);

    --dark-gray: hsl(320, 20%, 16%);
    --light-gray: hsl(220, 20%, 98%);

    --default-gradient: linear-gradient(240deg, var(--dark-red) 0%, var(--dark-blue) 100%);
    --accent-gradient: linear-gradient(240deg, var(--pink) 0%, var(--red) 100%);

    --default-color: var(--dark-blue);
    --accent-color: var(--pink);
    --text-color: var(--dark-blue);
    --background-color: var(--light-gray);

    /* Typography */
    --sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --serif: "source-serif-pro", "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;
    --code: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New", monospace;


    --fs-base: 24px;
    --ratio: 1.333;

    /* Calculate values */ 
    --fs-label: calc(var(--fs-base) / var(--ratio));
    --fs-body: var(--fs-base);
    --fs-h4: calc(var(--fs-base) * var(--ratio));
    --fs-h3: calc(var(--fs-h4) * var(--ratio));
    --fs-h2: calc(var(--fs-h3) * var(--ratio));
    --fs-h1: calc(var(--fs-h2) * var(--ratio));
    --fs-large: calc(var(--fs-h1) * var(--ratio));
    --fs-huge: calc(var(--fs-large) * var(--ratio));
    --fs-mega: calc(var(--fs-huge) * var(--ratio));

    /* Animation */
    --default-transition: all 0.2s ease-in-out;


    ${"" /* --nav-height-large: 120px; */}
    --nav-height: 60px;
    --container-mw: 1280px;
    --text-mw: calc(var(--container-mw) / 2);

    
    /* Spacing */


    /* Misc Styles */
    --default-radius: 20px;
    --mobile-device-radius: calc(1em / 2);
    
    --shadow-lg: 0 10px 30px hsla(0, 0%, 0%, 0.25);
    --shadow-iso: 20px 20px 30px hsla(0, 0%, 0%, 0.25);


    @media (max-width: 1600px) {
      --fs-base: 20px;
    }
    @media (max-width: 800px) {
      --fs-base: 16px;
    }
    @media (max-width: 330px) {
      --fs-base: 14px;
    }
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  ::selection {
    background: var(--default-color);
    color: var(--white);
  }

  html,
  body {
    background: var(--background-color);

    margin: 0;
    padding: 0;

    font-family: var(--serif);
    font-size: var(--fs-body);
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--text-color);

    scroll-behavior: smooth;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  code {
    font-family: var(--code);
    letter-spacing: -0.04em;
  }

  button {
    appearance: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  a {
    color: inherit;

    transition: all 0.1s ease-in-out;
    
    :visited {
      color: inherit;
    }

    :hover {
      :visited {
        color: var(--accent-color);
      }

      color: var(--accent-color);
    }

    :focus {
      outline: 2px solid var(--accent-color);
    }
  }

  p {
    font-size: var(--fs-body);
    margin-top: 0;
  }

  ${p => {
    if (p.isDarkMode) {
      return css`
        html,
        body {
          background: blue;
        }
      `
    }
  }}
`

export default GlobalStyles
