import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { Constraint, Section } from "./Layout"
import { TagsWrapper, Tag } from "./Tags"
import {
  ProjectTitle,
  ProjectSubtitle,
  ProjectThumbnailWrapper,
  ProjectThumbnail
} from "./ProjectElements"

const tablet = "800px"

const WorkSectionWrapper = styled(Section)`
  overflow: hidden;
`

const Title = styled.h1`
  position: relative;
  z-index: 1;

  font-size: var(--fs-mega);
  font-weight: 800;
  margin: 0;
  line-height: 1;
`

const ListOfProjects = styled.ul`
  max-width: var(--container-mw);
  margin: 0 auto;
  padding: 0;
`

const Project = styled.li`
  list-style: none;

  position: relative;
  display: flex;
  align-items: center;

  height: 60vh;
  margin-top: 40vh;

  @media (max-width: ${tablet}) {
    height: 40vh;
    margin-top: 20vh;
  }
`

const ProjectLink = styled(Link)`
  position: absolute;
  z-index: 1;

  color: var(--white);
  text-decoration: none;
  box-shadow: var(--shadow-lg);

  --mw: 610px;

  max-width: var(--mw);
  width: 100%;

  padding: 40px;
  background: var(--default-gradient);
  border-radius: var(--default-radius);

  transition: var(--default-transition);

  :hover {
    transform: scale(0.95);
    color: var(--white);
  }

  @media (max-width: 1600px) {
    --mw: 525px;
  }

  @media (max-width: ${tablet}) {
    --mw: 440px;
  }

  @media (max-width: 600px) {
    padding: 25px;
    align-self: flex-start;
  }
`

const WorkSection = () => {
  return (
    <WorkSectionWrapper>
      <Constraint>
        <Title>Work</Title>

        <ListOfProjects>
          <Project>
            <ProjectLink to="/pefin">
              <ProjectTitle arrow>Pefin</ProjectTitle>
              <ProjectSubtitle>AI Financial Advisor</ProjectSubtitle>
              <TagsWrapper>
                <Tag>Front End</Tag>
                <Tag>UX Design</Tag>
                <Tag>UX Engineering</Tag>
              </TagsWrapper>
            </ProjectLink>

            <ProjectThumbnailWrapper>
              <ProjectThumbnail
                src={require("../images/pefin/pefin-homepage.png")}
                alt="Pefin Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/pefin/pefin-financial-plan-01.png")}
                alt="Pefin Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/pefin/pefin-assistant-02.png")}
                alt="Pefin Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/pefin/pefin-onboarding-02.png")}
                alt="Pefin Project Thumbnail"
              />
            </ProjectThumbnailWrapper>
          </Project>

          <Project>
            <ProjectLink to="/quiikmeet">
              <ProjectTitle arrow>QuiikMeet</ProjectTitle>
              <ProjectSubtitle>Friend Finder App</ProjectSubtitle>
              <TagsWrapper>
                <Tag>Product Design</Tag>
                <Tag>Front End</Tag>
              </TagsWrapper>
            </ProjectLink>

            <ProjectThumbnailWrapper mobile>
              <ProjectThumbnail
                src={require("../images/quiikmeet/quiikmeet-01.png")}
                alt="QuiikMeet Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/quiikmeet/quiikmeet-02.png")}
                alt="QuiikMeet Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/quiikmeet/quiikmeet-03.png")}
                alt="QuiikMeet Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/quiikmeet/quiikmeet-04.png")}
                alt="QuiikMeet Project Thumbnail"
              />
            </ProjectThumbnailWrapper>
          </Project>

          <Project>
            <ProjectLink to="/github-page">
              <ProjectTitle arrow>Tammy Vu</ProjectTitle>
              <ProjectSubtitle>Personal Github Page</ProjectSubtitle>
              <TagsWrapper>
                <Tag>Visual Design</Tag>
                <Tag>Front End</Tag>
              </TagsWrapper>
            </ProjectLink>

            <ProjectThumbnailWrapper>
              <ProjectThumbnail
                src={require("../images/tammy-github/desktop-01.png")}
                alt="Tammy Vu’s Github Page Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/tammy-github/desktop-02.png")}
                alt="Tammy Vu’s Github Page Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/tammy-github/desktop-03.png")}
                alt="Tammy Vu’s Github Page Project Thumbnail"
              />

              <ProjectThumbnail
                src={require("../images/tammy-github/desktop-04.png")}
                alt="Tammy Vu’s Github Page Project Thumbnail"
              />
            </ProjectThumbnailWrapper>
          </Project>
        </ListOfProjects>
      </Constraint>
    </WorkSectionWrapper>
  )
}

export default WorkSection
