import React, { useState, useCallback } from "react"
import styled, { css } from "styled-components"
import { Logo } from "./Icons"
import { Constraint, Section } from "./Layout"

const NavWrapper = styled.nav`
  position: fixed;
  z-index: 100;
  top: var(--nav-height);
  left: 0;
  right: 0;

  height: var(--nav-height);
`

const NavWrapperInner = styled(Constraint)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const AppLogo = styled.a`
  --size: 60px;

  display: block;
  fill: white;
  background: var(--accent-gradient);

  width: var(--size);
  height: var(--size);

  padding: calc(var(--size) / 6);
  border-radius: calc(var(--default-radius) / 2);

  box-shadow: var(--shadow-lg);

  transition: all 0.1s ease-in-out;

  :hover {
    transform: scale(0.95);
    color: var(--white);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 480px) {
    --size: 50px;
  }
`

const DarkModeToggle = styled.button`
  width: 50px;
  height: 50px;
  border-radius: ${props => (props.isDarkMode ? "50%" : "10px")};
  background: white;
`

const Navbar = ({ isDarkMode, whenDarkMode }) => {
  return (
    <NavWrapper>
      <Section noVert>
        <NavWrapperInner>
          <AppLogo href="/">
            <Logo alt="logo" />
          </AppLogo>

          {/* <DarkModeToggle isDarkMode={isDarkMode} onClick={whenDarkMode} /> */}
        </NavWrapperInner>
      </Section>
    </NavWrapper>
  )
}

export default Navbar
