import React from "react"
import { Constraint, Section, TextConstraint } from "../components/Layout"
import {
  ProjectTitle,
  ProjectSubtitle,
  ProjectThumbnail,
  ProjectThumbnailWrapper,
  ProjectImgGroup,
  ProjectPageWrapper,
  ProjectHeroSection,
  ProjectHeroText,
  NextProject
} from "../components/ProjectElements"
import {
  TextLink,
  CopySubtitle,
  CopyTitle,
  CopyLabel,
  CopyLabelText
} from "../components/Typography"
import { TagsWrapper, Tag } from "../components/Tags"
import Footer from "../components/Footer"
import ScrollToTop from "../helpers/ScrollToTop"

const projectName = "Tammy Vu"

const TammyGithub = () => {
  return (
    <>
      <ScrollToTop />

      <ProjectPageWrapper>
        <Section>
          <Constraint>
            <ProjectHeroSection>
              <ProjectThumbnailWrapper projectPage>
                <ProjectThumbnail
                  src={require("../images/tammy-github/desktop-01.png")}
                  alt="Tammy Vu’s Github Page Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/tammy-github/desktop-02.png")}
                  alt="Tammy Vu’s Github Page Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/tammy-github/desktop-03.png")}
                  alt="Tammy Vu’s Github Page Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/tammy-github/desktop-04.png")}
                  alt="Tammy Vu’s Github Page Project Thumbnail"
                />
              </ProjectThumbnailWrapper>
            </ProjectHeroSection>

            <TextConstraint>
              <ProjectHeroText>
                <ProjectTitle>{projectName}</ProjectTitle>
                <ProjectSubtitle>Personal Github Page</ProjectSubtitle>
                <TagsWrapper>
                  <Tag>Visual Design</Tag>
                  <Tag>Front End</Tag>
                </TagsWrapper>
              </ProjectHeroText>

              <CopyLabel>Timeline</CopyLabel>
              <CopyLabelText>Late Summer &ndash; Fall 2017</CopyLabelText>

              <CopyLabel>Tools</CopyLabel>
              <CopyLabelText>Adobe XD, HTML, SCSS, jQuery</CopyLabelText>

              <CopyLabel>Visit Her Page</CopyLabel>
              <CopyLabelText>
                <TextLink href="https://tammylvu.github.io/">
                  https://tammylvu.github.io/
                </TextLink>
              </CopyLabelText>

              <CopySubtitle>Background</CopySubtitle>
              <p>
                Tammy Vu was a third year Computer Science student at Berkeley
                applying for internships. She wanted a personal GitHub page
                where she could showcase her past projects and experience.
              </p>

              <CopySubtitle>Design</CopySubtitle>
              <p>
                Since a personal GitHub page only allows for one page per
                project, I decided to start with a single page design. I chose
                to use sea green and light blue because Tammy said those were
                her favorite colors. Below you can see my initial high fidelity
                mockup. Since she didn’t have/couldn’t produce any screenshots
                for her projects, I decided to design each project as a box with
                text—each having a slightly different background color.
              </p>

              <ProjectImgGroup>
                <figure>
                  <img
                    src={require("../images/tammy-github/hifi-01.png")}
                    alt="Initial High Fidelity Mockup"
                  />
                </figure>
              </ProjectImgGroup>

              <p>
                From this point forward I started designing in the browser. One
                of the first major changes I made was changing the design to be
                mainly on black rather than on white. I felt that it was more on
                brand for a developer since they love dark mode
                everything—especially since the logo was set in a monospaced
                font. I also decided to make the type much larger than
                before—and with a gradient background—for maximum impact.
              </p>

              <CopySubtitle>Development</CopySubtitle>
              <p>
                I used HTML and SCSS for the markup and styling. I then used
                jQuery in conjunction with CSS animations to create a staggered
                lazy load type effect.
              </p>

              <p>
                You can check out the code{" "}
                <TextLink href="https://github.com/tammylvu/tammylvu.github.io">
                  here.
                </TextLink>
              </p>

              <CopySubtitle>Screenshots</CopySubtitle>
            </TextConstraint>

            <ProjectImgGroup>
              <figure>
                <img
                  src={require("../images/tammy-github/desktop-01.png")}
                  alt="Hero Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/tammy-github/desktop-02.png")}
                  alt="About Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/tammy-github/desktop-03.png")}
                  alt="Projects Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/tammy-github/desktop-04.png")}
                  alt="Resume Section"
                />
              </figure>
              <figure>
                <img
                  src={require("../images/tammy-github/desktop-05.png")}
                  alt="Resume Section: Menu Open"
                />
              </figure>
            </ProjectImgGroup>

            <ProjectImgGroup mobile rounded>
              <figure>
                <img
                  src={require("../images/tammy-github/mobile-01.png")}
                  alt="Hero Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/tammy-github/mobile-02.png")}
                  alt="About Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/tammy-github/mobile-03.png")}
                  alt="Projects Section"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/tammy-github/mobile-04.png")}
                  alt="Resume Section"
                />
              </figure>
              <figure>
                <img
                  src={require("../images/tammy-github/mobile-05.png")}
                  alt="Resume Section: Menu Open"
                />
              </figure>
            </ProjectImgGroup>
          </Constraint>
        </Section>

        {/* <Section>
          <Constraint>
            <NextProject to="/quiikmeet">QuiikMeet</NextProject>
          </Constraint>
        </Section> */}
      </ProjectPageWrapper>

      <Footer />
    </>
  )
}

export default TammyGithub
