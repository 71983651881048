import React, { useState, useCallback } from "react"
import styled from "styled-components"
import "./App.css"
import { BrowserRouter as Router } from "react-router-dom"
import GlobalStyles from "./GlobalStyles"
import { Route } from "react-router-dom"
import Homepage from "./pages/Homepage"
import Pefin from "./pages/Pefin"
import Navbar from "./components/Navbar"
import QuiikMeet from "./pages/QuiikMeet"
import TammyGithub from "./pages/TammyGithub"
import ScrollToTop from "./helpers/ScrollToTop"
import ChoreScore from "./pages/ChoreScore"

const AppWrapper = styled.div`
  overflow: hidden;
`

const App = ({ isDarkMode, whenDarkMode }) => {
  const [darkMode, setDarkMode] = useState(false)

  const toggleDarkMode = useCallback(
    e => {
      e.preventDefault()
      setDarkMode(!darkMode)
      console.log("lol")
    },
    [darkMode]
  )
  // const toggleDarkMode = useCallback(() => {
  //   setDarkMode(true)
  // }, [darkMode])
  return (
    <Router>
      {/* <ScrollToTop> */}
      <GlobalStyles isDarkMode={darkMode} />

      <AppWrapper
        className="App"
        isDarkMode={darkMode}
        // onClick={toggleDarkMode}
        whenDarkMode={toggleDarkMode}
      >
        <Navbar isDarkMode={isDarkMode} whenDarkMode={whenDarkMode} />
        <Route exact path="/" component={Homepage} />
        <Route path="/pefin" component={Pefin} />
        <Route path="/quiikmeet" component={QuiikMeet} />
        <Route path="/github-page" component={TammyGithub} />
        {/* <Route path="/chorescore" component={ChoreScore} /> */}
      </AppWrapper>
      {/* </ScrollToTop> */}
    </Router>
  )
}

export default App
