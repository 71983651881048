import styled, { css, keyframes } from "styled-components"
import { Link } from "react-router-dom"

const tablet = "800px"

export const ProjectPageWrapper = styled.div`
  overflow: hidden;
`

export const ProjectHeroSection = styled.section`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -5em;

  ${"" /* height: 100vh; */}
  ${"" /* background: var(--default-gradient); */}

  @media (max-width: ${tablet}) {
    margin-bottom: 0;
  }
`

export const ProjectHeroText = styled.div`
  margin-bottom: 2em;
`

export const ProjectTitle = styled.h1`
  font-size: var(--fs-h1);
  margin: 0;
  line-height: 1;

  color: inherit;

  @media (max-width: ${tablet}) {
    font-size: var(--fs-h2);
  }

  ${p => {
    if (p.arrow) {
      return css`
        ::after {
          content: " ↗";
          color: inherit;
          font-size: var(--fs-h1);
        }
      `
    }
  }}
`
export const ProjectSubtitle = styled.h2`
  font-size: var(--fs-h3);
  font-style: italic;
  font-weight: normal;
  line-height: 1;

  color: inherit;

  margin: 0.5em 0 1em 0;

  @media (max-width: ${tablet}) {
    font-size: var(--fs-h4);
    line-height: 1.15;
  }
`
export const ProjectThumbnailWrapper = styled.figure`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4vmin;

  --scale-value: 1.5;
  transform: rotateX(45deg) rotateZ(30deg) scale(var(--scale-value));
  transform-style: preserve-3d;

  @media (max-width: 480px) {
    --scale-value: 2.5;
  }

  ${p => {
    if (p.projectPage) {
      return css`
        --scale-value: 0.75;

        @media (max-width: 800px) {
          --scale-value: 1.5;
        }
      `
    }
  }}

  ${p => {
    if (p.mobile) {
      return css`
        grid-template-columns: 1fr 1fr 1fr 1fr;

        ${ProjectThumbnail} {
          border-radius: var(--mobile-device-radius);
        }

        @media (max-width: 480px) {
          ${"" /* --scale-value: 2; */}

          ${"" /* grid-template-columns: 1fr 1fr; */}
        }
      `
    }
  }}
`
export const dropIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 80px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

// function staggerDrop() {
//   let styles = ""
//   for (let index = 1; index < 4; index++) {
//     styles += `
//         :nth-of-type(${index + 1}) {
//           animation-delay: calc(0.05s * ${index});
//         }
//      `
//   }
//   return css`
//     ${styles}
//   `
// }

export const ProjectThumbnail = styled.img`
  width: 100%;
  box-shadow: var(--shadow-iso);
  transition: var(--default-transition);

  animation: 1s ${dropIn} ease-out;
  animation-fill-mode: both;

  :nth-of-type(2) {
    animation-delay: 0.2s;
  }
  :nth-of-type(3) {
    animation-delay: 0.4s;
  }
  :nth-of-type(4) {
    animation-delay: 0.6s;
  }
`

export const ProjectImgGroup = styled.div`
  max-width: 100%;
  margin: 2em 0;

  display: grid;
  grid-gap: 1em;

  figure {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  img {
    box-shadow: var(--shadow-lg);
    width: inherit;
    max-width: 100%;
  }

  figcaption {
    text-align: center;
    font-size: var(--fs-body);
    max-width: var(--text-mw);
    margin: 0.25em auto 1em auto;
  }

  ${p => {
    if (p.twoCol) {
      return css`
        grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));

        @media (max-width: 600px) {
          grid-template-columns: 1fr;
        }
      `
    }
  }}

  ${p => {
    if (p.mobile) {
      return css`
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

        figure {
        }

        img {
          border-radius: ${p =>
            p.rounded ? "var(--mobile-device-radius)" : null};
        }

        @media (max-width: 600px) {
          grid-template-columns: 1fr;
        }
      `
    }
  }}
`

export const NextProject = styled(Link)``
