import React from "react"
import { Constraint, Section, TextConstraint } from "../components/Layout"
import {
  ProjectTitle,
  ProjectSubtitle,
  ProjectThumbnail,
  ProjectThumbnailWrapper,
  ProjectImgGroup,
  ProjectPageWrapper,
  ProjectHeroSection,
  ProjectHeroText,
  NextProject
} from "../components/ProjectElements"
import {
  TextLink,
  CopyTitle,
  CopySubtitle,
  CopyLabel,
  CopyLabelText
} from "../components/Typography"
import { TagsWrapper, Tag } from "../components/Tags"
import Footer from "../components/Footer"
import ScrollToTop from "../helpers/ScrollToTop"

const projectName = "QuiikMeet"

const QuiikMeet = () => {
  return (
    <>
      <ScrollToTop />
      <ProjectPageWrapper>
        <Section>
          <Constraint>
            <ProjectHeroSection projectPage mobile>
              <ProjectThumbnailWrapper projectPage mobile>
                <ProjectThumbnail
                  src={require("../images/quiikmeet/quiikmeet-01.png")}
                  alt="QuiikMeet Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/quiikmeet/quiikmeet-02.png")}
                  alt="QuiikMeet Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/quiikmeet/quiikmeet-03.png")}
                  alt="QuiikMeet Project Thumbnail"
                />

                <ProjectThumbnail
                  src={require("../images/quiikmeet/quiikmeet-04.png")}
                  alt="QuiikMeet Project Thumbnail"
                />
              </ProjectThumbnailWrapper>
            </ProjectHeroSection>

            <TextConstraint>
              <ProjectHeroText>
                <ProjectTitle projectPage>{projectName}</ProjectTitle>
                <ProjectSubtitle>Friend Finder App</ProjectSubtitle>
                <TagsWrapper>
                  <Tag>Product Design</Tag>
                  <Tag>Front End</Tag>
                  <Tag>Branding</Tag>
                </TagsWrapper>
              </ProjectHeroText>

              <CopyLabel>Timeline</CopyLabel>
              <CopyLabelText>Fall 2015 &ndash; Spring 2016</CopyLabelText>

              <CopyLabel>Team</CopyLabel>
              <CopyLabelText>
                Design &mdash; Dang-Nam Vu, Tyler Mitchell
                <br />
                Development &mdash; Chris Clark, Lucas Haber, Michael Ng
              </CopyLabelText>

              <CopyLabel>View the Prototype</CopyLabel>
              <CopyLabelText>
                <TextLink href="http://meetmeapplication.herokuapp.com/">
                  http://meetmeapplication.herokuapp.com/
                </TextLink>
              </CopyLabelText>

              <p>
                <em>
                  Note: The app isn’t currently maintained, so you won’t be able
                  to log in, but you can still view the splash and tutorial
                  screens. For best results, use a mobile device or resize your
                  viewport/browser.
                </em>
              </p>

              <CopySubtitle>The Product</CopySubtitle>
              <p>
                QuiikMeet is a real time friend and activity finding app that
                aims to help you fill free time in your day by meeting new
                people. Users make an account by logging in through Facebook and
                are prompted to fill a small amount of personal information to
                create a profile. After completing their profile, users can meet
                new people that are nearby, available, and interested in similar
                activities.
              </p>

              <p>
                While active, the user can look through other active users’
                profiles and select a person they’re willing to meet up with.
                Once two people “match,” a chat client becomes available for
                them to decide when and where to meet.
              </p>

              <p>
                The app isn’t currently maintained, so you won’t be able to log
                in, but you can view the splash and tutorial screens{" "}
                <TextLink href="http://meetmeapplication.herokuapp.com/">
                  here.
                </TextLink>{" "}
                For best results, use a mobile device or resize your
                viewport/browser.
              </p>

              <p>
                Or you can click <TextLink href="#screenshots">here</TextLink>{" "}
                to jump to the final screenshots of the app.
              </p>

              <CopyTitle>Our Process</CopyTitle>

              <CopySubtitle>The Problem</CopySubtitle>
              <p>
                College classes are run from early in the morning until late at
                night. At my school, the earliest class started at 8pm and the
                latest class ended at 9pm. This leaves students with vastly
                different schedules and a lot of free time in between. Despite
                such a large concentration of people on campus, it can be hard
                to make friends outside of your classes, clubs, or residence.
              </p>

              <CopySubtitle>Competitor Analysis &amp; Branding</CopySubtitle>
              <p>
                We looked for products that facilitated meetups between users,
                such as Tinder, Coffee Meets Bagel, Lyft Driver Mode, MeetMyDog,
                and Nearify. From this we realized that none of these apps were
                doing specifically what we were—which was platonically meeting
                people to do something in real time. As a result, we decided to
                keep our features minimal and avoid competing with features of
                established apps that already have an existing place in the
                market due to our smaller budgets of time and money.
              </p>

              <p>
                Initially, the project was called MeetMe. In our initial
                competitor analysis, we failed to find that there was an app
                with the same name&mdash;and what’s worse&mdash;there was a news
                article where{" "}
                <TextLink href="https://www.wyff4.com/article/nasa-sold-moon-landing-footage-to-an-intern-for-dollar218-now-the-tapes-could-sell-for-millions/28377207">
                  someone was murdered
                </TextLink>{" "}
                using the app, which raised concerns of safety among our team.
                This got us thinking about adding safety features (such as
                ratings) and omitting features that could endanger users.
              </p>

              <p>
                After going through many iterations to change the product’s
                name, we landed on QuickMeet. However, we didn’t like the word
                looked—and didn’t think it would be good in a logo, so we
                stylized it to QuiikMeet, which allowed us to leverage the “ii”
                as a distinctive part of our logo.
              </p>
            </TextConstraint>

            <ProjectImgGroup twoCol>
              <figure>
                <img
                  src={require("../images/quiikmeet/logo-on-green.svg")}
                  alt="QuiikMeet Logo and Wordmark on Green"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/logo-on-white.svg")}
                  alt="QuiikMeet Logo and Wordmark on White"
                />
              </figure>
            </ProjectImgGroup>

            <TextConstraint>
              <CopySubtitle>Wireframes</CopySubtitle>

              <p>
                These are the final low fidelity wireframes. While creating the
                wireframes, we were refining our feature set and project
                planning. Some features that were cut include location sharing
                (due to safety and time constraint concerns) and notifications
                (since the app was real time invites would be responded to
                within a few minutes at most).
              </p>
            </TextConstraint>

            <ProjectImgGroup mobile>
              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-00.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-01.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-02.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-03.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-04.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-05a.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-05b.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/wireframes-05c.png")}
                  alt="QuiikMeet Wireframes: Login with Facebook"
                />
              </figure>
            </ProjectImgGroup>

            <TextConstraint>
              <CopySubtitle>Final Static Designs</CopySubtitle>

              <p>
                These are the final static mockups. Since we explicitly did not
                want our product to be a dating app, the only image we decided
                to use in the design was for the user’s profile. In theory,
                users would know what their match looks like, but not enough to
                match on vanity alone. Since users must log in via Facebook,
                there’s also a lower (but not impossible) chance of using a fake
                picture than if we were to ask for it separately.
              </p>
            </TextConstraint>

            <ProjectImgGroup mobile>
              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-00.png")}
                  alt="QuiikMeet High Fidelity Designs: Splash"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-01.png")}
                  alt="QuiikMeet High Fidelity Designs: Tutorial 01"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-02.png")}
                  alt="QuiikMeet High Fidelity Designs: Tutorial 02"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-03.png")}
                  alt="QuiikMeet High Fidelity Designs: Tutorial 03"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-04.png")}
                  alt="QuiikMeet High Fidelity Designs: Login with Facebook"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-05a.png")}
                  alt="QuiikMeet High Fidelity Designs: Pick a time"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-05b.png")}
                  alt="QuiikMeet High Fidelity Designs: Pick a time input field"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-05c.png")}
                  alt="QuiikMeet High Fidelity Designs: Activity selector"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-05d.png")}
                  alt="QuiikMeet High Fidelity Designs: Find a meet search completed"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-06.png")}
                  alt="QuiikMeet High Fidelity Designs: List of active users"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-07.png")}
                  alt="QuiikMeet High Fidelity Designs: Active user’s profile"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-08.png")}
                  alt="QuiikMeet High Fidelity Designs: Chat with matched user"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/hifi-09.png")}
                  alt="QuiikMeet High Fidelity Designs: Chat history"
                />
              </figure>
            </ProjectImgGroup>

            <TextConstraint>
              <CopySubtitle>Front End Development</CopySubtitle>
              <p>
                In order to make a cross platform MVP, we used{" "}
                <TextLink href="https://ionicframework.com/">Ionic,</TextLink> a
                cross-platform web based framework for building mobile apps,
                which allowed us to have more potential users to test the
                product.
              </p>

              <p>
                The front end was built using HTML and SCSS. Since both design
                team members were proficient in coding, we iterated on the UX
                and visual design directly in code.
              </p>

              <CopySubtitle>User Testing &amp; Revisions</CopySubtitle>
              <p>We learned 2 major things from our testing:</p>

              <p>
                Most of our participants were unwilling to fill out their
                profile, but wanted to see information about other people.
              </p>

              <p>
                If people are required to share information on a social app are
                the top 4 things they are most comfortable sharing their are
                name, age, occupation, and interests.
              </p>

              <p>
                After learning this we decided to add an onboarding section
                which requires users to fill out basic information such as their
                age and nickname, what they do (school/job), and what they like
                to do. We chose to use these specific fields because were the
                top choices when we asked users what features they would like to
                see in a profile before matching someone.
              </p>

              <ProjectImgGroup>
                <figure>
                  <img
                    src={require("../images/quiikmeet/onboarding-sketch.jpg")}
                    alt="Initial sketch of onboarding designs"
                  />

                  <figcaption>
                    An initial sketch of the onboarding design
                  </figcaption>
                </figure>
              </ProjectImgGroup>

              <CopySubtitle>Screenshots of the App</CopySubtitle>
            </TextConstraint>

            <ProjectImgGroup id="screenshots" mobile rounded>
              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-01.png")}
                  alt="Onboarding: Tutorial 01"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-02.png")}
                  alt="Onboarding: Tutorial 02"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-03.png")}
                  alt="Onboarding: Facebook Login"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-07.png")}
                  alt="Onboarding: Name and Age"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-08.png")}
                  alt="Onboarding: What do you do?"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-09.png")}
                  alt="Onboarding: What do you like to do?"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-04.png")}
                  alt="Find a Meet"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-05.png")}
                  alt="Find a Meet: Until When?"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-06.png")}
                  alt="Find a Meet: To Do What?"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-10.png")}
                  alt="Find available users to match with"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-11.png")}
                  alt="Someone else’s profile"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-12.png")}
                  alt="Invite accepted popup"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-13.png")}
                  alt="Chat with a matched person"
                />
              </figure>

              <figure>
                <img
                  src={require("../images/quiikmeet/quiikmeet-14.png")}
                  alt="Your Profile"
                />
              </figure>
            </ProjectImgGroup>
          </Constraint>
        </Section>

        {/* <Section>
          <Constraint>
            <NextProject to="/quiikmeet">QuiikMeet</NextProject>
          </Constraint>
        </Section> */}
      </ProjectPageWrapper>

      <Footer />
    </>
  )
}

export default QuiikMeet
