import React from "react"
import HeroSection from "../components/HeroSection"
import WorkSection from "../components/WorkSection"
import Footer from "../components/Footer"

const Homepage = () => {
  return (
    <>
      <HeroSection />

      <WorkSection />

      <Footer />
    </>
  )
}

export default Homepage
